.plorbin-effect-1 {
    background-image: url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}


.plorbin-effect-2 {
    animation: plorbin-effect-2 10s;
}
@keyframes plorbin-effect-2 {
	0% {
		text-shadow: 0 0 50px #fff;
		letter-spacing: .35em;
		opacity: 0;
		transform: rotateY(-90deg);
	}
	50% {
		text-shadow: 0 0 1px #fff;
		opacity: 0.8;
		transform: rotateY(0deg);
	}
	85% {
		text-shadow: 0 0 1px #fff;
		opacity: 0.8;
		transform: rotateY(0deg) translateZ(100px);
	}
	100% {
		text-shadow: 0 0 10px #fff;
		opacity: 0;
		transform: translateZ(130px);
		pointer-events: none;
	}
}


.plorbin-effect-3 {
    animation: text-shadow 2s ease-in-out infinite;
}
@keyframes text-shadow {
    0% {  
        transform: translateY(0);
        text-shadow: 
            0 0 0 #0c2ffb, 
            0 0 0 #2cfcfd, 
            0 0 0 #fb203b, 
            0 0 0 #fefc4b;
    }

    20% {  
        transform: translateY(-.1em);
        text-shadow: 
            0 0.125em 0 #0c2ffb, 
            0 0.25em 0 #2cfcfd, 
            0 -0.125em 0 #fb203b, 
            0 -0.25em 0 #fefc4b;
    }

    40% {  
        transform: translateY(0.1em);
        text-shadow: 
            0 -0.0625em 0 #0c2ffb, 
            0 -0.125em 0 #2cfcfd, 
            0 0.0625em 0 #fb203b, 
            0 0.125em 0 #fefc4b;
    }
    
   60% {
        transform: translateY(-.1em);
        text-shadow: 
            0 0.03125em 0 #0c2ffb, 
            0 0.0625em 0 #2cfcfd, 
            0 -0.03125em 0 #fb203b, 
            0 -0.0625em 0 #fefc4b;
    }

    80% {  
        transform: translateY(0);
        text-shadow: 
            0 0 0 #0c2ffb, 
            0 0 0 #2cfcfd, 
            0 0 0 #fb203b, 
            0 0 0 #fefc4b;
    }
}


#plorbin-text > p.plorbin-effect-4 {
    color: black;

    margin-left: -50vw;

}
.plorbin-effect-4 {
    text-shadow: .03em .03em 0 black;
}
.plorbin-effect-4::after {
    content: attr(data-shadow);
    z-index: 0;

    text-shadow: none;
    background-image:
        linear-gradient(
            45deg,
            transparent 45%,
            hsla(48,20%,90%,1) 45%,
            hsla(48,20%,90%,1) 55%,
            transparent 0
        );
    background-size: 0.1em 0.1em;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: plorbin-effect-4 15s linear infinite;
}
@keyframes plorbin-effect-4 {
    0% { background-position: 0 0 }
    0% { background-position: 100% -100% }
}


.plorbin-effect-5 {
    background-image: url(https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExOXh1Y3RzOGdweWUyd3ltb3N6Z2dyYTFlNmpubTU5cjR3cmI0cHZkdyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Hh9sPYeSWhAUx6NDBM/giphy.webp);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}


.plorbin-effect-6 {
    background-image: url(https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExdDg3a2MybWl1dm90c2tyc2g1cmJlMmRlZGU5bGpqZjNjaHM4Z2FiMSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/mFYZPGWguAKN5Ay6TZ/giphy.webp);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}

.plorbin-effect-7 {
    background-image: url(https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExeHo4aGd2ZGw0aG1oMzNzY3BzanJzOW8wbGxtMjkxdGt6emo3dDAydyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/McOkSmNstXfPDLmfhP/giphy.webp);
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}


.plorbin-effect-8 {
    background-image: url(https://media1.tenor.com/m/FHFesnagxfIAAAAC/kitpup-yomber.gif);
    background-position: center -120px;
    background-size: cover;
    color: transparent;
    -moz-background-clip: text;
    -webkit-background-clip: text;
}