@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Mono:wght@100..700&display=swap');

div.plorbin {
  position: absolute;
  overflow: hidden !important;
  

  .plorbin-content {    
    height: 100vh;
    width: 100vw;

    background-color: black;
    
    /*             */
    /* PLORBIN-PIC */
    /*             */
    &:has(#plorbin-pic) {
      background-color: rgb(216,214,210);

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    #plorbin-pic {
      animation: plorbin 3s infinite ease-in-out;
      
      background-image: url('../../public/img/plorbin.png');
      background-size: 100%;

      box-shadow: 5px 5px 20px 10px rgb(216,214,210) inset;
    }


    @media (max-width: 900px) {
      #plorbin-pic {
        margin-top: -2vh;

        height: 50vw;
        width: 50vw;
      }
    }
    
    
    @media (min-width: 900px) {
      #plorbin-pic {
        height: 75vh;
        width: 75vh;
      }
    }
  }


  /*              */
  /* PLORBIN-TEXT */
  /*              */
  &:has(#plorbin-text) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  #plorbin-text {
    color: white;
    font-family: 'Bebas Neue';
    white-space: nowrap;
    width: auto;

    @media (max-width: 900px) {
      font-weight: 800;
      font-size: 5em;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }

    @media (min-width: 900px) {
      font-size: 15em;

      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
    }
  }
}






@keyframes plorbin {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(2) rotate(360deg);
  }
}
